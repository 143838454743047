import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import vid from "../assets/labs.mp4"
import astro from "../images/gatsby-astronaut.png"

const IndexFrancais = () => (
  <Layout language="fr">
    <SEO title="Home" />
    <div id="video-wrap">
    <video id="headerVideo" autoPlay muted loop >
  <source src={vid} type="video/mp4" />
</video>
    <div className="exploreWrapper"><Link to="/fr/our-mission"><button className="explore">Explorons ensemble</button></Link></div>
    </div>
  </Layout>
)

export default IndexFrancais
